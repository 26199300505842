<template>
  <div>
    <a-row>
      <a-col :span="16">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="套餐名称">{{ data.package_name }}</a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="2" :offset="1">
        <span>共{{ data.item_count }}项</span>
      </a-col>
      <a-col :span="4" :offset="1">
        <span style="color:green">金额：{{ data.package_price_display }}元</span>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="items"
      :pagination="false"
      @change="fetchData"
    />
  </div>
</template>

<script>
import { findOrderPackages } from '@/api/order'

export default {
  name: 'Packages',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      items: []
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '序号',
          dataIndex: 'id'
        },
        {
          title: '项目名称',
          dataIndex: 'name'
        },
        {
          title: '类型',
          dataIndex: 'item_type'
        },
        {
          title: '计价单位',
          dataIndex: 'unit'
        },
        {
          title: '计价个数',
          dataIndex: 'count'
        },
        {
          title: '价格(元)',
          dataIndex: 'price_display'
        },
        {
          title: '项目介绍',
          dataIndex: 'remark'
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findOrderPackages(this.id).then(res => {
        if (res.code === 0) {
          this.data = res.data
          this.items = res.data.item_list
        }
      })
    }
  }
}
</script>
